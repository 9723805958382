@tailwind base;
@tailwind components;
@tailwind utilities;
/* montserrat google font */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  background-color: hsl(34, 20%, 79%);
}
html {
  scroll-behavior: smooth;
}

a:visited {
  color: hsl(248, 50%, 47%);
}
.cs-font-32 {
  font-size: 2rem;
  line-height: 34px;
}

.loginSignupBackground {
  background-color: hsl(34, 20%, 79%);
  height: 100vh;
  height: 100dvh;
  height: 100lvh;
  width: 100%;
}
/* loader in the todosform.jsx */
.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;
  background-color: hsl(0, 0%, 100%);
  box-shadow: 30px 0 0 hsl(24, 57%, 29%);
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite;
  background-color: hsl(24, 57%, 29%);
  box-shadow: 30px 0 0 hsl(0, 0%, 100%);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 hsl(24, 57%, 29%);
  }
  50% {
    box-shadow: 0 0 0 hsl(24, 57%, 29%);
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 hsl(24, 57%, 29%);
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 hsl(0, 0%, 100%);
  }
  50% {
    box-shadow: 0 0 0 hsl(0, 0%, 100%);
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 hsl(0, 0%, 100%);
    margin-top: 0;
  }
}
